/* Variables */
:root {
	--container-width: 1500px;
	--fonts: 'Poppins', 'Arial', 'Sans-Serif';
	--primary-color: #eb1313;
	--dark-primary: #af0e0e;
}

/* Reset */
* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

/* Element Styling */
body {
	line-height: 1.3;
	font-family: var(--fonts);
	background: #fafafa;
	overflow-x: hidden;
	color: #333;
}

button[type='submit'] {
	-webkit-appearance: none;
}

a {
	text-decoration: none;
	cursor: pointer;
}

a > p {
	display: inline;
}

ul {
	list-style: none;
}
img {
	width: 100%;
}

/* Handle language change */
.page-wrapper.mk .en {
	display: none !important;
}

.page-wrapper.en .mk {
	display: none !important;
}

/* Utility Classes */
.logo {
	min-width: 60px;
	width: 14vw;
	max-width: 170px;
}

.container {
	max-width: var(--container-width);
	margin: auto;
	padding: 0 1.5rem;
}

.flex-container {
	width: 100%;
	display: flex;
}

.container-small {
	max-width: 65rem;
	margin: auto;
	padding: 0 1.5rem;
}

.btn {
	display: inline-block;
	background: #fafafa;
	padding: 0.85rem 1.65rem;
	border-radius: 30px;
	font-size: 1rem;
	outline: none;
	border: none;
	color: var(--primary-color);
	cursor: pointer;
	transition: 0.4s all ease;
	font-weight: 400;
	box-shadow: rgba(0, 0, 0, 0.2) 0 0 15px;
}

.btn:hover {
	background: var(--primary-color);
	color: #fafafa;
}

.btn-block {
	width: 100%;
	display: block;
	font-size: 1.1rem;
}

.btn-large {
	font-size: 1.2rem;
	padding: 1rem 1.75rem;
}

.btn-inverse {
	background: var(--primary-color);
	color: #fafafa;
}

.btn-inverse:hover {
	background: var(--dark-primary);
}

.btn-topleft {
	position: absolute;
	top: 0;
	left: 0;
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
	border-top-right-radius: 0;
}

.btn-bottomright {
	position: fixed;
	bottom: 0;
	right: 0;
	border-radius: 0;
	border-top-left-radius: 15px;
	padding: 0.5rem;
}

.btn-topright {
	position: absolute;
	top: 0;
	right: 0;
	border-radius: 0;
	border-bottom-left-radius: 25px;
}

.space-between {
	align-items: space-between;
	justify-content: space-between;
}

.S-heading {
	font-size: 1.3rem;
	font-weight: 600;
}
.M-heading {
	font-size: 2.3vw;
	font-weight: 400;
}
.ML-heading {
	font-size: 2.3rem;
	font-weight: 400;
}
.L-heading {
	font-size: 3rem;
	font-weight: 400;
}
.XL-heading {
	font-size: 6vw;
	font-weight: 400;
}

.lead {
	font-size: 1.2rem;
	font-weight: 300;
	line-height: 1.5;
}

.bold {
	font-weight: 600;
}

.line-smaller {
	width: 85%;
	margin: auto;
}
.line-light {
	border: none;
	background: rgba(43, 47, 57, 0.2);
	height: 1px;
}

.primary-bg {
	background: var(--dark-primary);
	color: #fafafa;
}

.mt-1 {
	margin-top: 1rem;
}
.mt-1-5 {
	margin-top: 1.5rem;
}
.mt-2 {
	margin-top: 2rem;
}
.mt-3 {
	margin-top: 3rem;
}
.mt-6 {
	margin-top: 6rem;
}
.mt-12 {
	margin-top: 12rem;
}
.ml-3 {
	margin-left: 3rem;
}
.mr-0-5 {
	margin-right: 0.5rem;
}
.mr-1 {
	margin-right: 1rem;
}
.mr-3 {
	margin-right: 3rem;
}
.mr-4 {
	margin-right: 4rem;
}
.my-1 {
	margin-top: 1rem;
	margin-bottom: 1rem;
}
.my-2 {
	margin-top: 2rem;
	margin-bottom: 2rem;
}
.my-4 {
	margin-top: 4rem;
	margin-bottom: 4rem;
}
.mb-1 {
	margin-bottom: 1rem;
}
.mb-1-5 {
	margin-bottom: 1.5rem;
}
.mb-2 {
	margin-bottom: 2rem;
}
.mb-3 {
	margin-bottom: 3rem;
}
.mb-4 {
	margin-bottom: 4rem;
}
.mb-5 {
	margin-bottom: 5rem;
}
.p-1 {
	padding: 1rem;
}
.p-2 {
	padding: 2rem;
}
.py-1 {
	padding-top: 1rem;
	padding-bottom: 1rem;
}
.py-4 {
	padding-top: 4rem;
	padding-bottom: 4rem;
}
.pb-4 {
	padding-bottom: 4rem;
}
.px-2 {
	padding-left: 2rem;
	padding-right: 2rem;
}
.pr-4 {
	padding-right: 4rem;
}

.center {
	text-align: center;
	padding: 1rem auto;
}

.right {
	text-align: right;
}

.medium {
	font-size: 1.125rem;
}

.clip {
	max-width: 60%;
	margin: auto;
}

.light {
	color: #6d6d6d;
}

.header {
	font-weight: 600;
	font-size: 1.25rem;
}

.label {
	display: block;
	font-size: 1.3rem;
	font-weight: 200;
	margin-bottom: 0.5rem;
}

.input-group {
	width: 100%;
	padding: 1rem;
}

.flex-group {
	width: 100%;
	display: flex;
}

.input-group input,
.input-group textarea {
	width: 100%;
}

.input {
	padding: 0.65rem 1.2rem;
	border-radius: 20px;
	border: none;
	outline: none;
	font-size: 1rem;
}
textarea.input {
	padding: 1rem 1.5rem;
	font-family: 'Poppins';
	resize: none;
}

.input::placeholder {
	opacity: 0.8;
	font-family: var(--fonts);
}

/* Main Styling */
.main-landing {
	height: 100vh;
	color: #fafafa;
	position: relative;
	overflow: hidden;
}

.main-landing .main-nav {
	z-index: 1000;
}

.hideNav .main-nav:first-child {
	visibility: hidden;
}

.sticky-nav {
	background: #fafafa;
	padding: 0.25rem;
	color: #333;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	border-bottom: 1px solid rgba(43, 47, 57, 0.1);
}

.sticky-nav .M-heading {
	font-size: clamp(1rem, 2.3vw, 1.3rem);
}

.sticky-nav .logo {
	width: 60px;
}

.main-nav.sticky-nav .links > a {
	color: rgba(43, 47, 57, 0.7);
	font-weight: 300;
}

.sticky-nav .lang-change-btn {
	color: var(--primary-color);
}
.main-nav.sticky-nav .links > a::after {
	background: var(--primary-color);
}

.main-nav .container {
	display: flex;
	align-items: center;
}

.main-nav .logo-container {
	display: flex;
	align-items: center;
	flex: 1.25;
}
.main-nav .links {
	flex: 1;
}
.main-nav .btns {
	flex: 0.75;
}

.main-nav .links a {
	margin-right: 2rem;
	font-size: 1.1rem;
	color: #fafafa;
	font-weight: 100;
	position: relative;
}

.main-nav .links a::after {
	content: '';
	position: absolute;
	background: #fafafa;
	height: 1px;
	bottom: -5px;
	left: 50%;
	transform: translateX(-50%);
	transition: 0.4s all ease;
	width: 0;
	opacity: 0;
}

.main-nav .links a:hover::after {
	width: 90%;
	opacity: 1;
}

.main-nav .links a.current {
	font-weight: 300;
}

.lang-change-btn {
	margin-left: 2rem;
	outline: none;
	background: none;
	border: none;
	cursor: pointer;
	color: #fafafa;
	font-size: 1rem;
	font-weight: 300;
}

.main-content {
	height: 73%;
	width: 100%;
	display: flex;
	z-index: 100;
}

.main-content .left {
	flex: 1;
	display: flex;
	justify-content: center;
	z-index: 100;
}
.main-content .right {
	flex: 1;
	position: relative;
	bottom: 0;
	right: 0;
}

.main-img {
	position: absolute;
	bottom: 0;
	right: 0;
	z-index: 100;
	max-height: 110%;
	width: auto;
	max-width: 150%;
	display: none;
}

.main-bg {
	width: 100%;
	height: 100%;
	z-index: -1;
	position: absolute;
	top: 0;
	left: 0;
	display: none;
}
.bg1 {
	background: url('./static/background1.png') no-repeat center center/cover;
	display: block;
}
.bg2 {
	background: url('./static/background2.png') no-repeat center center/cover;
}
.bg3 {
	background: url('./static/bg3test.png') no-repeat center center/cover;
}
.bg4 {
	background: url('./static/pvcbgtest.png') no-repeat center center/cover;
}
.bg5 {
	background: url('./static/background5.png') no-repeat center center/cover;
}
.bg6 {
	background: url('./static/background6.png') no-repeat center center/cover;
}
.bg7 {
	background: url('./static/darkenedImg.png') no-repeat center center/cover;
}
.bg8 {
	background: url('./static/background8.png') no-repeat center center/cover;
}

.slide-from-left {
	animation: slide-from-left 0.85s linear forwards;
}
.slide-from-right {
	animation: slide-from-right 0.85s linear forwards;
}
@keyframes slide-from-left {
	from {
		left: 0;
	}
	to {
		left: -100%;
	}
}

@keyframes slide-from-right {
	from {
		left: 100%;
	}
	to {
		left: 0;
	}
}

.slide-to-right-hide {
	animation: slide-to-right-hide 0.85s linear forwards;
}
.slide-to-right-show {
	animation: slide-to-right-show 0.85s linear forwards;
}

@keyframes slide-to-right-hide {
	from {
		left: 0;
	}
	to {
		left: 100%;
	}
}
@keyframes slide-to-right-show {
	from {
		left: -100%;
	}
	to {
		left: 0;
	}
}

.main-img:first-child {
	display: inline;
}

.slide {
	animation-name: slide;
	animation-duration: 1.1s;
}

@keyframes slide {
	from {
		bottom: -100%;
	}
	to {
		bottom: 0;
	}
}

.main-content .left {
	display: flex;
	justify-content: flex-start;
	position: relative;
}

.main-content .left .text-container {
	right: 5%;
	top: 0;
	position: absolute;
	text-align: right;
	display: none;
}

.main-content .left .text-container:first-child {
	display: block;
}

.fade {
	animation-name: fade;
	animation-duration: 1.4s;
}

@keyframes fade {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

.main-content .left .text-container p {
	opacity: 0.8;
}

.main-landing .arrows {
	position: absolute;
	bottom: 12%;
	left: 5%;
	z-index: 100;
}

.main-landing .arrows .fas {
	transform: scale(1.5);
	cursor: pointer;
}

.main-landing .arrows .fas:first-child {
	margin-right: 5rem;
}

.main-landing .indicator {
	position: absolute;
	right: 2rem;
	top: 55%;
	transform: translateY(-50%);
	z-index: 100;
}

.main-landing .indicator p {
	transform: rotate(-90deg);
	color: #333;
}

.main-landing .indicator .line {
	width: 3px;
	height: 15px;
	margin-top: 2px;
	margin-left: 50%;
	transform: translateX(-50%);
	background: #333;
	opacity: 0.2;
	transition: 0.4s all ease;
}

.main-landing .indicator .line:last-child {
	margin-bottom: 5px;
}

.main-landing .indicator .line:first-child {
	margin-top: 7px;
}

.main-landing .indicator .line.full {
	background: var(--primary-color);
	opacity: 1;
}

.main-landing .shape {
	position: absolute;
	bottom: 0;
	right: 0;
	width: 55%;
	height: 70%;
	z-index: 10;
}

.main-landing .shape img {
	width: 100%;
	height: 100%;
}

/* Our Work */
.main-work-text {
	flex: 1.2;
}
.work-images {
	flex: 1;
}

.main-work-text .title {
	display: flex;
	align-items: center;
}

.main-work-text .fas {
	color: var(--primary-color);
	margin-right: 1.5rem;
}

.work-images {
	padding-left: 5rem;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
}

.work-images .top,
.work-images .mid,
.work-images .bottom {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
}

.work-images a {
	margin: 0.15rem;
	margin-bottom: 0;
}

.work-img {
	width: 100%;
	height: unset;
}

.work-img-container {
	position: relative;
	overflow: hidden;
	width: 100%;
}

.work-img-container p {
	position: absolute;
	text-align: center;
	z-index: 100;
	top: -20%;
	left: 50%;
	transform: translateX(-50%) translateY(-50%);
	opacity: 0;
	color: #fafafa;
	font-size: 1.2rem;
	transition: 0.7s all ease-in;
}

.work-img-container:hover p {
	opacity: 1;
	top: 50%;
}

.work-img-container::after {
	content: '';
	position: absolute;
	top: -200%;
	left: -200%;
	width: 200%;
	height: 200%;
	background: var(--primary-color);
	transition: 0.7s all ease-in-out;
	transform: rotate(45deg);
	opacity: 0.7;
}

.work-img-container:hover::after {
	display: inline;
	top: -50%;
	left: -50%;
}

.work-list > li {
	padding: 0.1rem 0;
}
.work-list > li a {
	color: rgba(43, 47, 57, 0.7);
	transition: 0.3s all ease;
	font-size: 1.1rem;
}

.work-list > li a:hover {
	color: rgb(17, 17, 19);
}

.contact-container {
	color: #fafafa;
	background: url('./static/contactBg.jpg') no-repeat center center/cover;
}

.contact-form {
	text-align: left;
}

.about-container.flex-container > div {
	flex: 1;
}

.about-img img {
	width: 100%;
}

/* Work Page */
.work-bg {
	height: 50vh;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #fafafa;
}

.work-link {
	color: #333;
	font-weight: 400;
	margin-right: 1.5rem;
	transition: 0.4s all ease;
	position: relative;
}
.work-link:last-child {
	margin-right: 0;
}

.work-link:hover {
	color: var(--primary-color);
}

.navbar-secondary {
	border-bottom: 1px solid rgba(43, 47, 57, 0.2);
	overflow: hidden;
}

.work-link.current::after {
	content: '';
	position: absolute;
	left: 50%;
	bottom: -100%;
	width: 17px;
	height: 17px;
	background: var(--primary-color);
	transform: translateX(-50%);
	border-radius: 50%;
}

.bg-solar {
	background: url('./static/solarBg.jpg') no-repeat bottom center/cover;
}
.bg-radiators {
	background: url('./static/radiatorsBg.jpg') no-repeat center center/cover;
}
.bg-electricity {
	background: url('./static/electricityBg.jpg') no-repeat center center/cover;
}
.bg-plumbing {
	background: url('./static/plumbingBg.jpg') no-repeat center center/cover;
}
.bg-heating {
	background: url('./static/heatingBg.jpg') no-repeat bottom center/cover;
}
.bg-PVC {
	background: url('./static/pvcBg.jpg') no-repeat center center/cover;
}
.bg-AC {
	background: url('./static/acBg.jpg') no-repeat top center/cover;
}

.img-container {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
}

.img-container img {
	height: 120px;
	width: auto;
	margin: 0.5rem;
	cursor: pointer;
}

.modal {
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background: rgba(0, 0, 0, 0.8);
	display: grid;
	place-items: center;
	z-index: 10002;
}
.modal-hide {
	display: none;
}

.modal .arrow-left,
.modal .arrow-right {
	z-index: 10001;
	color: var(--primary-color);
	position: fixed;
	padding: 0.5rem 0.75rem;
	top: 50%;
	transform: translateY(-50%);
	background: #fafafa;
	cursor: pointer;
	transition: 0.3s all ease;
	opacity: 0.65;
}

.modal .arrow-left:hover,
.modal .arrow-right:hover {
	color: #fafafa;
	background: var(--primary-color);
}

.modal .arrow-left {
	left: 1rem;
}
.modal .arrow-right {
	right: 1rem;
}

.carousel {
	width: 100%;
	height: 100%;
	position: relative;
}

.carousel-img {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translateX(-50%) translateY(-50%);
	height: 80vh;
	width: auto;
	display: none;
}

@keyframes slide-from-right-to-middle {
	from {
		left: 2000px;
	}
	to {
		left: 50%;
	}
}
@keyframes slide-from-middle-to-left {
	from {
		left: 50%;
	}
	to {
		left: -2000px;
	}
}
@keyframes slide-from-left-to-middle {
	from {
		left: -2000px;
	}
	to {
		left: 50%;
	}
}
@keyframes slide-from-middle-to-right {
	from {
		left: 50%;
	}
	to {
		left: 2000px;
	}
}

.image-upload.center {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

.image-upload-preview {
	width: 13rem;
	height: 13rem;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	margin-bottom: 1rem;
}

.image-upload-preview img {
	width: 100%;
	object-fit: cover;
}

.slide-from-right-to-middle {
	animation: slide-from-right-to-middle 0.85s linear;
	animation-fill-mode: forwards;
}
.slide-from-middle-to-left {
	animation: slide-from-middle-to-left 0.85s linear;
	animation-fill-mode: forwards;
}
.slide-from-left-to-middle {
	animation: slide-from-left-to-middle 0.85s linear;
	animation-fill-mode: forwards;
}
.slide-from-middle-to-right {
	animation: slide-from-middle-to-right 0.85s linear;
	animation-fill-mode: forwards;
}

#uploadForm {
	border-radius: 30px;
	max-width: 40rem;
	background: var(--dark-primary);
	color: #fafafa;
	box-shadow: rgba(175, 14, 14, 0.5) 0 0 12px;
}

.radio-inputs {
	display: flex;
	flex-direction: column;
}

.radio-inputs label {
	margin-top: 0.5rem;
	font-size: 1rem;
}

.flex-container .phone-container,
.flex-container .address-container {
	transform: scale(0.8);
}

a.address-container {
	color: #fafafa;
}

.loader {
	width: 50px;
	height: 50px;
	border: 7px solid var(--primary-color);
	border-radius: 50%;
	border-top-color: #fafafa;
	animation: loader 1s linear 0s infinite;
	margin: auto;
}

@keyframes loader {
	to {
		transform: rotate(360deg);
	}
}

/* RESPONSIVE MEDIA QUERIES */
@media (max-width: 920px), (orientation: portrait) {
	.links {
		display: flex;
		justify-content: flex-end;
		align-items: center;
		position: relative;
		flex: 0.25;
	}

	.hamburger-menu {
		cursor: pointer;
		position: relative;
		z-index: 10001;
	}

	.hamburger-menu .menu {
		background: #fafafa;
		display: flex;
		flex-direction: column;
		position: absolute;
		width: 8.5rem;
		height: 10rem;
		top: 120%;
		left: 50%;
		transform: translateX(-50%);
		visibility: hidden;
		transition: 0.4s ease all;
		box-shadow: rgba(0, 0, 0, 0.2) 0 0 15px;
	}
	
	.hamburger-menu .menu a {
		color: var(--primary-color);
		padding: 0.5rem 0.75rem;
		font-weight: 400;
		transition: 0.3s ease all;
		font-size: 1rem;
	}

	.menu.fade-in {
		visibility: visible;
		animation: fadeIn 0.4s linear;
		animation-fill-mode: forwards;
	}
	.menu.fade-out {
		animation: fadeOut 0.4s linear;
		animation-fill-mode: forwards;
	}
	
	@keyframes fadeIn {
		0% {
			opacity: 0;
			height: 0;
		}
		
		100% {
			opacity: 1;
			height: 10rem;
		}
	}
	
	@keyframes fadeOut {
		100% {
			opacity: 0;
			height: 0;
		}
	}

	.hamburger-menu .menu a.current {
		color: var(--dark-primary);
	}

	.hamburger-menu .menu a.current::after {
		display: none;
	}

	.hamburger-menu .menu a:hover {
		color: var(--dark-primary);
	}

	.hamburger-menu .menu a:hover::after {
		width: 0;
	}

	.container-small .flex-container {
		flex-direction: column;
	}
	.work-images {
		padding: 1rem 1.5rem;
	}
	.main-work-text {
		text-align: center;
	}
	.main-work-text .title {
		justify-content: center;
	}
}


@media (max-width: 768px), (orientation: portrait) {
	.main-content {
		flex-direction: column;
		height: 90%;
	}
	
	.XL-heading {
		font-size: 2.9rem;
	}
	.M-heading {
		font-size: 1rem;
	}
	
	.main-landing .shape {
		width: 100%;
		height: 40%;
	}
	
	.main-img {
		height: 100%;
		width: auto;
		max-width: auto;
		max-height: auto;
		left: 50%;
		transform: translateX(-50%);
	}
	
	.main-content .left {
		justify-content: center;
		align-items: center;
	}
	
	.main-content .left .text-container {
		left: 0;
		width: 100%;
		text-align: center;
	}
	
	.main-landing .indicator {
		right: 1rem;
		top: 85%;
	}
	
	.main-landing .arrows {
		bottom: 2%;
		left: 50%;
		transform: translateX(-50%);
		color: #333;
	}
	
	.clip {
		max-width: 80%;
	}
	
	.carousel-img {
		height: auto;
		width: 80vw;
	}
}

@media (max-width: 920px) {
	.flex-group {
		flex-direction: column;
	}
	.address-container {
		margin-top: 2rem;
	}
	#about .flex-container {
		flex-direction: column-reverse;
	}
	#about .about-img {
		display: flex;
		justify-content: center;
		align-items: center;
		margin: 0;
	}
	
	.navbar-secondary {
		overflow: visible;
	}
	
	.navbar-secondary .hamburger-menu .menu {
		height: 20rem;
	}
	
	.navbar-secondary .menu.fade-in {
		animation: fadeInLarge 0.4s linear;
		animation-fill-mode: forwards;
	}
	
	@keyframes fadeInLarge {
		0% {
			opacity: 0;
			height: 0;
		}
		
		100% {
			opacity: 1;
			height: 18rem;
		}
	}
}

@media (max-height: 500px) and (max-width: 400px) {
	.text-container a,
	.text-container p {
		display: none;
	}
}

@media (min-width: 1600px) {
	.XL-heading {
		font-size: 6.5rem;
	}
}

@media (min-width: 768px) and (orientation: portrait) {
	.XL-heading {
		font-size: 5rem;
	}
}